<template>
  <div v-if="page_loading_full">
    <loading :page_loading_full="page_loading_full" />
  </div>
  <div v-else class="mt-0" align="center">

    <!-- ปริ้น barcode -->
    <div v-if="type == 0">
      <v-card :elevation="0" width="160" v-for="(i,index) in item.quantity" :key="index" class="pages">
        <v-card-title class="pa-2 pb-0">
          <!-- <div class="col-12 text-truncate text-left pa-0 color-black fn-10"> -->
          <div class="col-12 line-clamp lineheight075 text-left pa-0 color-black fn-10 ">
            {{ item.productCode == null || item.productCode == '' ? null : '['+item.productCode+'] ' }} {{ item.name }} {{ item.prop1Description }} {{item.prop2Description }}
          </div>
        </v-card-title>
        <v-card-text class="px-2 pt-0 pb-0">

          <VueBarcode   :value="item.barcode" height="24" width="1" fontSize= "0" marginTop="1" marginBottom="1" />
          <v-row class="color-black lineheight075" style="margin-top:-4px !important">
            <v-col cols="12" class="fn-8">
              {{ item.barcode }}
            </v-col>
          </v-row>

          <v-row class="color-black lineheight075">
            <v-col cols="12" align="left" class="fn-8">
              Sku : {{ item.sku }}
            </v-col>
            <v-col cols="8" align="left" class="fn-8 pr-1">
              {{ GetDateTime() }}
            </v-col>
            <v-col cols="4" align="right" class="fn-8 pl-1">
            {{ index + 1 }}/{{ item.quantity }}
            </v-col>
          </v-row>
        </v-card-text>
        <div style="page-break-after: always;"></div>
      </v-card>
    </div>

      <!-- ปริ้น barcode -->
      <div v-else-if="type == 1">
      <v-card :elevation="0" width="320"  >
        <v-card-text class="pa-3 color-black">
          <v-row>
            <v-col cols="6" align="left" class="color-black">
              <span>LotNo : {{ item.importExportDetailId }} </span><br>
              <span>จำนวน : {{ item.quantity }} </span>

            </v-col>
            <v-col  cols="6">
              <VueBarcode :value="item.lotNo" height="40" width="2" fontSize= "0" marginTop="7" marginBottom="1" />
            </v-col>
          </v-row>

          <v-row align="left">
            <v-col cols="12"  align="left">
              {{!item.barcode ? "" : "barcode" +" : "}} {{ item.barcode }} {{item.barcode && item.sku ? ",": "" }} {{!item.sku ? ""  : "SKU" + " : "}}  {{ item.sku }}
            </v-col>
            <v-col cols="12"  align="left">
              <span v-if="item.productCode!=null && item.productCode!=''"> รหัสสินค้า : {{ item.productCode }}</span>
              <span v-if="item.productCode!=null && item.productCode!='' && (item.prop1Description || item.prop2Description)"> ,</span>
              <span v-if="item.prop1Description || item.prop2Description">  {{!item.prop1Description && !item.prop2Description ? "" : "ลักษณะ" + "  : "}}  {{ item.prop1Description }}  {{item.prop2Description }}</span>
            </v-col>
            <v-col v-if="item.manufactureDateNow !=null" cols="12"  align="left">
              วันที่ผลิต :{{ item.manufactureDateNow }}
            </v-col>
            <v-col v-if="item.expirationDateNow !=null" cols="12"  align="left">
              วันที่หมดอายุ : {{ item.expirationDateNow }}
            </v-col>
            <v-col cols="12"  align="left">
              <br> {{ item.name }}
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </div>

    <!-- ปริ้น รายละเอียด -->
    <v-card v-else-if="type == 2" :elevation="0" width="400" class=" mt-5">
      <v-card-title class="pa-3">
        <div class="col-12 text-truncate pa-0 color-black">
          {{ ((item.sku!=null && item.sku!='') ? "SKU : ":"")  }} {{ item.sku }}
        </div>
      </v-card-title>
      <v-card-text class="pa-3 pt-0">

        <VueBarcode :value="item.barcode" height="40" width="2" fontSize= "0" marginTop="1" marginBottom="1" />
        <v-row class="color-black">
          <v-col cols="12">
            {{ item.barcode }}
          </v-col>
        </v-row>

        <v-row class="color-black py-1">
          <v-col cols="4" class="pb-2" align="left">
            ชื่อสินค้า :
          </v-col>
          <v-col cols="8" class="pb-2" align="left">
            {{ item.name }}
          </v-col>

          <v-col cols="4" class="pb-2" align="left">
            รหัสสินค้า :
          </v-col>
          <v-col cols="8" class="pb-2" align="left">
            {{ item.productCode }}
          </v-col>

          <v-col cols="4" class="pb-2" align="left">
            ลักษณะ :
          </v-col>
          <v-col cols="8" class="pb-2" align="left">
            {{ item.prop1Description }} {{item.prop2Description }}
          </v-col>

          <v-col cols="4" class="pb-2" align="left">
            RackNo :
          </v-col>
          <v-col cols="8" class="pb-2" align="left">
            {{ item.rackNo }}
          </v-col>

          <v-col cols="4" class="pb-2" align="left">
            Lot name :
          </v-col>
          <v-col cols="8" class="pb-2" align="left">
            {{ item.lotName }}
          </v-col>

          <v-col cols="4" class="pb-2" align="left">
            วันที่ผลิต :
          </v-col>
          <v-col cols="8" class="pb-2" align="left">
            {{ item.manufactureDateNow }}
          </v-col>

          <v-col cols="4" class="pb-2" align="left">
            วันที่หมดอายุ :
          </v-col>
          <v-col cols="8" class="pb-2" align="left">
            {{ item.expirationDateNow }}
          </v-col>
        </v-row>

        <VueBarcode :value="item.lotNo" height="40" width="2" fontSize= "0" marginTop="7" marginBottom="1" />
        <v-row class="color-black">
          <v-col cols="12">
            LotNo : {{ item.importExportDetailId }}
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

  </div>
</template>

<script>
  import Loading from '@/website/components/Loading'
  import { GetDateTime } from '@/website/global_function'
  import VueBarcode from 'vue-barcode'
  export default {
    components: {
      VueBarcode
    },
    data: () => ({
      page_loading_full: true,
      type: 0,
      item: null,
    }),
    created() {
      this.type = parseInt(localStorage.getItem('printbarcodetype'))
      this.item = JSON.parse(localStorage.getItem('printbarcodeitem'))
      this.page_loading_full = false
      setTimeout(function(){ print() }, 500);
    },
    methods: {
      GetDateTime
    },
  }
</script>

<style scoped>
  >>>.vue-barcode-element{
    width: 100%  !important;
  }
  @media print {
     .pages {
      /* size: 10cm 10cm;
      width: 10cm;
      height: 10cm;
      margin: 0;
      align-items: center;
      margin-top: 4px !important;
      margin-left: 4px  !important;
      margin-right: 4px  !important;
      padding-left: 4px !important;
      align-items: center;
      display: flex; */
      page-break-after: always;
    }
  }
</style>
